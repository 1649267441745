import axios from "axios";
import axiosRetry from "axios-retry";
import urljoin from "url-join";
import store from "store";
import { v4 as uuid } from "uuid";

const DEBUG = process.env.NODE_ENV !== "production";

const CONFIG = {
	baseUrl: DEBUG
		? `http://${window.location.hostname}:9090/`
		: "https://adventurestore.me/",
	timeoutMs: 10 * 1000,
	storyId: null,
	assetId: null,
	sessionIdKey: "fl-session",
};

const client = axios.create();
axiosRetry(client, {
	retries: 5,
	retryDelay: axiosRetry.exponentialDelay,
	retryCondition: axiosRetry.isNetworkOrIdempotentRequestError,
	shouldResetTimeout: true,
});

export function getBaseUrl() {
	return CONFIG.baseUrl;
}

export function configure(newConfig) {
	Object.assign(CONFIG, newConfig);
}

export function getIpInfo() {
	return apiCall("ip-info", "get", {
		timingLabel: "getIpInfo",
	});
}

export function storyInteract({ interactionType, sessionId, data }) {
	return apiCall(`story/${CONFIG.storyId}/interact`, "put", {
		data: {
			asset_id: CONFIG.assetId,
			interaction_type: interactionType,
			session_id: CONFIG.sessionId || sessionId,
			data,
		},
		timingLabel: "storyInteract",
	});
}

export function storyPipelineStep({ step }) {
	return apiCall(`story/${CONFIG.storyId}/pstep/${step}`, "put", {
		timingLabel: "storyPipelineStep",
		failSilently: true,
	});
}

export function getClientTrackingId() {
	let id = store.get(CONFIG.sessionIdKey);
	if (!id) {
		id = uuid();
		store.set(CONFIG.sessionIdKey, id);
	}
	return id;
}

export function apiCall(url, method, options) {
	const { params, data, noRetry, timingLabel, extra, failSilently } =
		options || {};
	let { headers } = options || {};

	const extraOptions = extra || {};
	if (noRetry) {
		extraOptions["axios-retry"] = {
			retries: 0,
		};
	}

	headers = headers || {};
	headers["X-FL-TID"] = getClientTrackingId();

	const promise = client.request({
		url: urljoin(CONFIG.baseUrl, "/api", url),
		method,
		timeout: CONFIG.timeoutMs,
		params,
		data,
		timingLabel,
		headers,
		...extraOptions,
	});

	if (failSilently) {
		promise = promise.catch(() => apiSilentFailure(timingLabel));
	}

	return promise;
}
