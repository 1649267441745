import styles from "./Widget.module.css";

import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import classnames from "classnames";

const DEFAULT_POSITION = "bottomRight";

export default function Widget({ config, onChatClick }) {
	const [dialogOpen, toggleDialog] = useState(false);

	return (
		<Portal>
			<Dialog
				visible={dialogOpen}
				config={config || {}}
				onClose={() => toggleDialog(false)}
			/>
			<Bubble
				open={dialogOpen}
				config={config || {}}
				onClick={() => toggleDialog(!dialogOpen)}
			/>
		</Portal>
	);
}

function Portal({ children }) {
	return createPortal(children, document.body);
}

function Bubble({ open, config, onClick }) {
	const { bubble, position } = config;

	return (
		<div
			className={classnames(
				styles.bubble,
				bubble?.className,
				open ? styles.open : null,
				styles[position || DEFAULT_POSITION]
			)}
			onClick={onClick}
		>
			{open ? "CLOSE" : "HELP"}
		</div>
	);
}

function Dialog({ visible, config, onClose }) {
	const { hints, chat, position } = config;
	const [tile, setTile] = useState("menu");

	function handleAction(action) {
		setTile(action);
	}

	function handleScreenClose() {
		setTile("menu");
	}

	return (
		<div
			className={classnames(
				styles.dialog,
				styles[`tile_${tile}`],
				visible ? styles.visible : null,
				styles[position || DEFAULT_POSITION]
			)}
		>
			<DialogTile visible={tile === "menu"}>
				<Menu config={config} onAction={handleAction} />
			</DialogTile>
			<DialogTile visible={tile === "chat"}>
				<LiveChat {...chat} onClose={handleScreenClose} />
			</DialogTile>
			<DialogTile visible={tile === "hints"}>
				<Hints
					{...hints}
					visible={tile === "hints"}
					onClose={handleScreenClose}
				/>
			</DialogTile>
		</div>
	);
}

function DialogTile({ visible, children }) {
	return (
		<div
			className={classnames(
				styles.dialog__tile,
				visible ? styles.visible : null
			)}
		>
			{children}
		</div>
	);
}

function Menu({ visible, config, onAction }) {
	const { dialogTitle, hints, chat, feedback } = config;

	return (
		<div className={styles.menu}>
			{dialogTitle ? (
				<div className={styles.menu__title}>{dialogTitle}</div>
			) : null}
			{hints ? (
				<MenuButton
					text={hints.buttonText || "I need a hint!"}
					onClick={() => onAction("hints")}
				/>
			) : null}
			{feedback ? <FeedbackMenuButton feedback={feedback} /> : null}
			{chat ? (
				<MenuButton
					text={chat.buttonText || "Chat with a live person"}
					onClick={() => onAction("chat")}
				/>
			) : null}
		</div>
	);
}

function FeedbackMenuButton({ feedback }) {
	const { buttonText, method, url } = feedback;

	function clickHandler() {
		if (method === "url") {
			openPopup(url, "feedbackPopup", 800, 800);
		}
	}

	return (
		<MenuButton
			text={buttonText || "Leave feedback"}
			onClick={clickHandler}
		/>
	);
}

function MenuButton({ text, ...props }) {
	return (
		<Button spaceTop {...props}>
			{text}
		</Button>
	);
}

function Hints({ visible, method, hints, onClose }) {
	return (
		<div className={styles.hints}>
			<Hint text={hints && hints[0].text} visible={visible} />
			<Button inline spaceTop onClick={onClose}>
				{"Close"}
			</Button>
		</div>
	);
}

function Hint({ method, visible, text }) {
	const [actualText, setActualText] = useState(null);

	// TODO: support "method"

	useEffect(() => {
		if (visible && !actualText) {
			setActualText(text);
		} else if (!visible) {
			setActualText(null);
		}
	}, [visible, text, actualText]);

	return <div className={styles.immediateHint}>{actualText}</div>;
}

function LiveChat({ method, iframeSrc, onClose }) {
	if (method === "iframe") {
		return (
			<div className={styles.liveChat}>
				<iframe
					className={styles.liveChat__iframe}
					title="Live Chat"
					src={iframeSrc}
				/>
				<Button spaceTop onClick={onClose}>
					{"Close chat"}
				</Button>
			</div>
		);
	}
	return null;
}

function Button({ inline, spaceTop, className, children, ...props }) {
	return (
		<div
			className={classnames(
				className,
				styles.button,
				spaceTop ? styles.spaceTop : null,
				inline ? styles.inline : null
			)}
			{...props}
		>
			{children}
		</div>
	);
}

function openPopup(url, name, width, height) {
	const top = window.top.outerHeight / 2 + window.top.screenY - height / 2;
	const left = window.top.outerWidth / 2 + window.top.screenX - width / 2;
	window.open(
		url,
		"feedbackPopup",
		`height=${height},width=${width},top=${top},left=${left},resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=yes`
	);
}
